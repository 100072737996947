import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const SiteMetadata = (props) => {
  let {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        ...SiteDataFragment
      }
    }
  `);

  title = props.title ? props.title : title;
  description = props.description ? props.description : description;

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&amp;display=swap"
        rel="stylesheet"
      />
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SiteMetadata;
