import React from "react";
import Navigation from "../navigation/navigation.js";
import SiteMetadata from "../site-metadata/site-metadata.js";

const Page = (props) => {
  return (
    <>
      <SiteMetadata title={props.title} description={props.description} />
      <div className="container">
        <Navigation />
        {props.children}
      </div>
    </>
  );
};

export default Page;
