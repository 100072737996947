import React from "react";
import { Link } from "gatsby";
import icon from "../../images/icon.jpg";
import navigationStyles from "./navigation.module.scss";

const Navigation = () => {
  return (
    <header className={navigationStyles.header}>
      <div class={navigationStyles.header__content}>
        <img className={navigationStyles.logo} src={icon} alt="App Icon" />
        <nav className={navigationStyles.navigation}>
          <Link
            className={navigationStyles.navigation__item}
            to="/"
            activeClassName={navigationStyles.active}
          >
            Home
          </Link>
          <Link
            className={navigationStyles.navigation__item}
            to="/about"
            activeClassName={navigationStyles.active}
          >
            About
          </Link>
          <a
            href="https://github.com/bytecrumbs/sglandtransport"
            target="_blank"
            rel="noopener noreferrer"
            title="SG Land Transport – Open source"
            className={navigationStyles.navigation__item}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" data-tags="github">
              <g fill="#444" transform="scale(0.03125 0.03125)">
                <path d="M512 12.672c-282.88 0-512 229.248-512 512 0 226.261 146.688 418.133 350.080 485.76 25.6 4.821 34.987-11.008 34.987-24.619 0-12.16-0.427-44.373-0.64-87.040-142.421 30.891-172.459-68.693-172.459-68.693-23.296-59.093-56.96-74.88-56.96-74.88-46.379-31.744 3.584-31.104 3.584-31.104 51.413 3.584 78.421 52.736 78.421 52.736 45.653 78.293 119.851 55.68 149.12 42.581 4.608-33.109 17.792-55.68 32.427-68.48-113.707-12.8-233.216-56.832-233.216-253.013 0-55.893 19.84-101.547 52.693-137.387-5.76-12.928-23.040-64.981 4.48-135.509 0 0 42.88-13.739 140.8 52.48 40.96-11.392 84.48-17.024 128-17.28 43.52 0.256 87.040 5.888 128 17.28 97.28-66.219 140.16-52.48 140.16-52.48 27.52 70.528 10.24 122.581 5.12 135.509 32.64 35.84 52.48 81.493 52.48 137.387 0 196.693-119.68 240-233.6 252.587 17.92 15.36 34.56 46.763 34.56 94.72 0 68.523-0.64 123.563-0.64 140.203 0 13.44 8.96 29.44 35.2 24.32 204.843-67.157 351.403-259.157 351.403-485.077 0-282.752-229.248-512-512-512z" />
              </g>
            </svg>
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
